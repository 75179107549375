import { onLCP, onFID, onCLS, onFCP, onINP, onTTFB, Metric } from "web-vitals";

type WebVitalsOptions = {
  trackEvent: (
    category: string,
    action: string,
    label?: string,
    value?: string | number
  ) => void;
  eventCategory: string;
  includeInDevelopment?: boolean;
  metrics: string[];
};

function sendToAnalytics(metric: Metric, options: WebVitalsOptions) {
  const { name, delta, entries } = metric;

  if (!options.metrics.includes(name)) {
    return;
  }

  const opts = {
    category: options.eventCategory,
    action: name,
    label: window.location.href,
    value: Math.round(delta),
  };

  // Calculate the request time by subtracting from TTFB
  // everything that happened prior to the request starting.
  if (name === "TTFB") {
    opts.value = delta - entries[0].startTime;
  }
  if (options.includeInDevelopment && process.env.NODE_ENV !== "production") {
    console.log(opts.category, opts.action, opts.label, opts.value);
    return;
  }
  options.trackEvent(opts.category, opts.action, opts.label, opts.value);
}

export async function webVitals({ options }: { options: WebVitalsOptions }) {
  try {
    onLCP(metric => sendToAnalytics(metric, options));
    onFID(metric => sendToAnalytics(metric, options));
    onCLS(metric => sendToAnalytics(metric, options));
    onFCP(metric => sendToAnalytics(metric, options));
    onINP(metric => sendToAnalytics(metric, options));
    onTTFB(metric => sendToAnalytics(metric, options));
  } catch (err) {
    console.error("Web-Vitals", err);
  }
}
