import { getCookieValue } from "@utils/cookies";
import React, {
  ComponentType,
  MouseEventHandler,
  ReactEventHandler,
} from "react";

// --------------------------------------------------------------------------------
//                               Event Fields Handling
// --------------------------------------------------------------------------------

function getUTMParameters() {
  const urlParams = new URLSearchParams(window.location.search);

  const utms = {
    utm_source: urlParams.get("utm_source") || null,
    utm_medium: urlParams.get("utm_medium") || null,
    utm_campaign: urlParams.get("utm_campaign") || null,
    utm_term: urlParams.get("utm_term") || null,
    utm_content: urlParams.get("utm_content") || null,
  };

  return utms;
}

/**
 * Logic inspired by `api/afffiliate.ts` file inside `accuranker-functions` repository
 */
function getAaid(
  urlParams: URLSearchParams,
  accurankerReferral: string | undefined
) {
  let aaid = urlParams.get("aaid");

  const refCode = urlParams.get("ref_code");
  if (!aaid && refCode) {
    aaid = refCode;
  }

  if (!aaid && !accurankerReferral) {
    aaid = "G04PK9JTXB74"; // "our" referral
  }

  return aaid;
}

/** Logic inspired by `functions/api/affiliate.ts` in `accuranker-functions` project and `grank/apps/affiliate/consumer.py` in `grank` project */
function getAffiliateParameters() {
  const urlParams = new URLSearchParams(window.location.search);

  const accurankerReferral = getCookieValue("accuranker_referral");
  const campaign = urlParams.get("c");
  const placement = urlParams.get("p");
  const unique = urlParams.get("u");
  const irClickId = urlParams.get("irclickid");

  return {
    ar_affiliate_referral: accurankerReferral || null,
    ar_affiliate_aaid: getAaid(urlParams, accurankerReferral) || null,
    ar_affiliate_referral_locked:
      getCookieValue("accuranker_referral_locked") || null,
    ar_affiliate_campaign: campaign || null,
    ar_affiliate_placement: placement || null,
    ar_affiliate_unique: unique || null,
    ar_affiliate_ir_click_id: irClickId || null,
  };
}

function getDevicePixelRatio() {
  if (
    window.devicePixelRatio === null ||
    window.devicePixelRatio === undefined
  ) {
    return null;
  }
  return Math.round(window.devicePixelRatio * 100);
}

const getData = (armaTag: string, armaType: string, armaValue?: string) => ({
  ar_event_tag: armaTag,
  ar_event_type: armaType,
  ar_event_value: armaValue || null,
  etc_pathname: window.location.pathname || null,
  etc_referrer: document.referrer || null,
  etc_url: window.location.href || null, // full url sent in case we want to backfill some data in the future
  etc_scroll_depth: window.scrollY ?? null,
  etc_screen_width: window.screen.width ?? null,
  etc_screen_height: window.screen.height ?? null,
  etc_viewport_width: window.innerWidth ?? null,
  etc_viewport_height: window.innerHeight ?? null,
  etc_device_pixel_ratio: getDevicePixelRatio(),
  ...getUTMParameters(),
  ...getAffiliateParameters(), // accuranker affiliate stuff sent in case we want to use it in the future
});

/**
 *
 * A Blob with 'application/json' MIME type is used because `navigator.sendBeacon` can't set headers.
 *
 * The Blob's type property implicitly sets the 'Content-Type' of the beacon request.
 */
function getBlob(data: any) {
  return new Blob([JSON.stringify(data)], {
    type: "application/json",
  });
}

/**
 * POST using `navigator.sendBeacon` so request can outlive the page.
 *
 * Note: We use `sendBeacon` rather than `fetch`, because Firefox does not support the `keepalive` option for `fetch`.
 */
function sendData(data: any) {
  navigator.sendBeacon(
    "/api/arma",
    getBlob({
      ...data,
      etc_fingerprint: window.fp || null,
    })
  );
}

// --------------------------------------------------------------------------------
//                        Sending Interval and Event Listeners
// --------------------------------------------------------------------------------

let isVisibilityChangeListenerAdded = false;

function addVisibilityChangeListener() {
  if (!isVisibilityChangeListenerAdded) {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    isVisibilityChangeListenerAdded = true;
  }
}

function removeVisibilityChangeListener() {
  if (isVisibilityChangeListenerAdded) {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    isVisibilityChangeListenerAdded = false;
  }
}

function handleVisibilityChange() {
  if (document.visibilityState === "hidden") {
    processQueue(true);
  }
}

let isTerminationListenerAdded = false;

function addTerminationListener() {
  const terminationEvent = "onpagehide" in self ? "pagehide" : "unload";
  if (!isTerminationListenerAdded) {
    window.addEventListener(terminationEvent, handleTermination);
    isTerminationListenerAdded = true;
  }
}

function removeTerminationListener() {
  const terminationEvent = "onpagehide" in self ? "pagehide" : "unload";
  if (isTerminationListenerAdded) {
    window.removeEventListener(terminationEvent, handleTermination);
    isTerminationListenerAdded = false;
  }
}

function handleTermination() {
  processQueue(true);
}

let sendingInterval: number | undefined = undefined;

function addSendingInterval() {
  if (sendingInterval === undefined) {
    sendingInterval = window.setInterval(processQueue, 500);
  }
}

function clearSendingInterval() {
  clearInterval(sendingInterval);
  sendingInterval = undefined;
}

// --------------------------------------------------------------------------------
//                                   Queue Handling
// --------------------------------------------------------------------------------

const MESSAGE_QUEUE: any[] = [];

const MAX_RETRY_COUNT = 3;
let CURRENT_RETRY_COUNT = 0;

/**
 * Queue is implemented to increase the chance that fingerprint is set before sending the data.
 */
function processQueue(force = false) {
  if (!window.fp && CURRENT_RETRY_COUNT < MAX_RETRY_COUNT && !force) {
    // If fingerprint is not set, try again later.
    CURRENT_RETRY_COUNT++;
    return;
  }

  // Send all messages in the queue.
  while (MESSAGE_QUEUE.length > 0) {
    const data = MESSAGE_QUEUE.shift();
    sendData(data);
  }

  // When the queue is empty, clear the interval and remove event listeners.
  clearSendingInterval();
  removeVisibilityChangeListener();
  removeTerminationListener();
}

/**
 *
 * @param armaTag Event Tag, e.g. "hero-start-trial-button"
 * @param armaType Event Type, e.g. "click"
 */
export function queueARMABeacon(
  armaTag: string | undefined,
  armaType: string,
  armaValue?: string
) {
  if (armaTag) {
    MESSAGE_QUEUE.push(getData(armaTag, armaType, armaValue));

    if (sendingInterval === undefined) {
      processQueue();
      addSendingInterval();
      addVisibilityChangeListener();
      addTerminationListener();
    }
  }
}

/**
 * Enhances an event handler with a non-blocking network call to AccuRanker Marketing Analytics.
 *
 * It's primarily intended for use with the `onClick` event handler of HTML elements.
 *
 * @param handler The original click event handler.
 * @returns A new click event handler that includes the analytics call.
 */
export function handleWithARMA<T extends HTMLElement>(
  armaType: string,
  handler?: ReactEventHandler<T>
): ReactEventHandler<T> {
  return event => {
    const { armaTag, armaValue } = event.currentTarget.dataset;
    queueARMABeacon(armaTag, armaType, armaValue);

    // Call the provided onClick event handler if one exists.
    handler?.(event);
  };
}

/** *
 * Represents the properties specific to AccurankerMarketingAnalytics (ARMA).
 *
 * These are usually passed as props to components that need ARMA tracking.
 */
type ARMAProps = {
  /** Identifier tag for the event or component.
   *
   * Used for AccurankerMarketingAnalytics (ARMA).
   */
  armaTag?: string;
  armaValue?: string;
};

/**
 * This HOC adds AccuRanker Marketing Analytics (ARMA) tracking to a component.
 */
export function withARMA<
  P extends { onClick?: MouseEventHandler<HTMLElement> }
>(WrappedComponent: ComponentType<P>) {
  return ({ onClick, armaTag, armaValue, ...restProps }: P & ARMAProps) => {
    const props = {
      "data-arma-tag": armaTag,
      ...(armaValue ? { "data-arma-value": armaValue } : {}),
    };
    return (
      <WrappedComponent
        {...(restProps as P)}
        {...props}
        onClick={handleWithARMA("click", onClick)}
      />
    );
  };
}
