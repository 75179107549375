export const getCookieValue = (cookie: string): string | undefined =>
  document.cookie
    .split("; ")
    .find(row => row.startsWith(`${cookie}=`))
    ?.split("=")[1];

export const userCookieValue = (): string | undefined =>
  getCookieValue("is_user");

export const hubspotCookieValue = (): string | undefined =>
  getCookieValue("hubspotutk");

export const setCookie = (cname: string, cvalue: string): void => {
  if (!cname || !cvalue) return;
  const d = new Date();
  d.setTime(d.getTime() + 1000 * 24 * 60 * 60 * 1000); // 1000 days
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};domain=accuranker.com;samesite=lax;secure`;
};
