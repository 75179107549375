import React, { createContext, useContext, ReactNode } from "react";

export type GlobalPageContext = {
  [key: string]: any;
};
const GlobalPageContext = createContext<GlobalPageContext>({});

export const usePageContext = () => useContext(GlobalPageContext);

export const GlobalContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: GlobalPageContext;
}) => {
  return (
    <GlobalPageContext.Provider value={value}>
      {children}
    </GlobalPageContext.Provider>
  );
};
